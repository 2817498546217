// Use This URL for testing
const URL = "lims.kesartesting.com";

export const BASE_URL = `https://${URL}/api/`;
export const DOMAIN = `https://${URL}`;
export const MAX_COLS = 30;
export const ID_BASE = 1;
export const SRF_ID_BASE = 1;

// TODO: need to move to DB
export const BANK_DETAILS = {
  "Bank Name": "State Bank OF India, CBD Belapur Navi Mumbai",
  "Bank A/c. No.": "39813124209",
  "IFSC Code": "SBIN0061520",
};

export const weightUncertaintySupportiveValues ={
  barometer: 0.60,
  tempIndicator:0.35,
  rhIndicator:1.76,
  weightBox:7950,
};

export const GST_NUMBER = "27DSVPK5381Q1ZH";

export const DISABLED_MODULE =["Client Complaints","Client Feedback","User","Attendance","MarkAttendance","CoordinatesAddress","Settings","Datasheet Template","OfflineMode","WCR Report","Discipline","Department Analytics","Supplier Invoice","Training Resources","Assigned Duc","Inward Register"]; 

export const DEVICE_TYPE = ["desktop", "tablet", "mobile"];

export const LATEST_HASH = "89e09b5893d3f6da022ade0dbd903a52d47c63c6"
